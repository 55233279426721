<template>
  <div class="proyect">
    <Header
      nameProject="Mibus"
      subtitle="Aplicación para la gestión del transporte público de la ciudad."
      img="img/mibusapp.png"
    />
    <Section
      intro="El proyecto Mibus consiste en una aplicación para la gestión del transporte público de la ciudad para ayudar a los usuarios a visualizar los trayectos, sus paradas y gestiona su tarjeta transporte.  
"
      description="El objetivo de este proyecto es crear una aplicación para fomentar el uso e informar a los usuarios los métodos de transporte público de su ciudad. En este caso, utilizando el transporte de Bus urbano para la ciudad de Talavera de la Reina, ya que actualmente la información que utilizan es compleja y difícil de entender.  
"
      fecha="Julio 2021 - In progres"
      rol="Product designer"
      servicios="Research · UI Design · UX Design"
      title="Solución"
      problem="En cuanto a los trayectos, podrán marcar un punto de la ciudad y la aplicación le seleccionara los trayectos que puede utilizar, pudiendo compararlos y ver los tiempos de llegada reales y las paradas. 

Las notificaciones de tus trayectos y paradas favoritas para que no pierdas tu bus ni tu tiempo de espera además de mantenerte informado en caso de incidencia.
"
    />

    <div class="casestudy container">
      <img class="caseImg" src="img/mibus_routes.png" alt="Pantallas del proyecto Mibus"/>
      <h3>Proximamente case study</h3>
      <div>〰</div>
      <ul class="pagination">
        <li v-for="item in object" :key="item" class="project">
          <router-link :to="{ path: item.link }" class="link"
            >{{ item.msgButton }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Section from "@/components/Section.vue";

export default {
  name: "MIbus",
  components: {
    Header,
    Section,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      object: [
        {
          link: "/Vera",
          img: "img/vera.png",
          msgButton: "← Vera",
        },
        {
          link: "/Mimo",
          img: "img/aula.png",
          msgButton: "Mimo →",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>


.casestudy {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin: 6rem auto 2rem auto;
  }

  ul {
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0;

    li {
      display: flex;
      text-decoration: none;
      align-items: center;

      a {
        font-size: 2.5rem;
        font-family: "DM Serif Display", sans-serif;

      }
    }
  }
}
</style>
